module.exports = [{
      plugin: require('/home/circleci/election-live/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Libre Baskerville:400,400i,700","Noto Sans:400,700","Bai Jamjuree:400,600"]}},
    },{
      plugin: require('/home/circleci/election-live/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/election-live/src/components/PageLayout.js"},
    },{
      plugin: require('/home/circleci/election-live/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-43653558-20"},
    },{
      plugin: require('/home/circleci/election-live/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"403066120477972"},
    },{
      plugin: require('/home/circleci/election-live/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
