// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-party-js": () => import("/home/circleci/election-live/src/pages/party.js" /* webpackChunkName: "component---src-pages-party-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/election-live/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-about-js": () => import("/home/circleci/election-live/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dev-index-js": () => import("/home/circleci/election-live/src/pages/dev/index.js" /* webpackChunkName: "component---src-pages-dev-index-js" */),
  "component---src-pages-dev-inspect-js": () => import("/home/circleci/election-live/src/pages/dev/inspect.js" /* webpackChunkName: "component---src-pages-dev-inspect-js" */),
  "component---src-pages-dev-kitchen-sink-js": () => import("/home/circleci/election-live/src/pages/dev/kitchen-sink.js" /* webpackChunkName: "component---src-pages-dev-kitchen-sink-js" */),
  "component---src-pages-overview-js": () => import("/home/circleci/election-live/src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/circleci/election-live/.cache/data.json")

